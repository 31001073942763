import React from 'react'
import Greetings from './Greetings'
import Registration from './Registration'
import ParticleSystem from 'components/other/ParticleSystem'

const Welcome = () => {
	const [opacity, setOpacity] = React.useState(0)

	React.useEffect(() => {
		// increase opacity
		const timer = setInterval(() => {
			setOpacity(opacity + 0.1)
		}, 1)

		return () => {
			clearInterval(timer)
		}
	}, [])

  return (
	<div className='Page'>
		<Greetings />
		<Registration />
		<ParticleSystem opacity={opacity}/>
	</div>
  )
}

export default Welcome