import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import Camera from "components/posts/postEditor/Camera";

import * as utils from "utils.js";
import configData from "config.json";
import { Skeleton } from "@mui/material";

const imageDisplay = {
  width: "100%",
};

const buttonsContainer = {
  position: "relative",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  top: "-50px",
};

export default function PostEditor({ open, setOpen, onAccept }) {
  const [image, setImage] = React.useState(null);
  const [text, setText] = React.useState("");
  const [sendOK, setSendOK] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setImage(null);
    setText("");
    setIsLoading(false);
  };

  const handleSubmit = () => {
    console.log("submit");
    const token = localStorage.getItem("token");
    fetch(configData.SERVER_URL + "/posts", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      body: JSON.stringify({
        text: text,
        image: image,
      }),
    }).then(async (response) =>
      response.ok ? onAccept() : utils.handleStandardErrorCode(response)
    );
  };

  const back = () => {
    setImage(null);
    setIsLoading(false);
  };

  const checkSendOK = () => {
    if (!isLoading && image || text) {
      setSendOK(true);
    } else {
      setSendOK(false);
    }
  };

  useEffect(() => {
    checkSendOK();
  }, [image, text, isLoading]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>Leave a post</DialogTitle>
        <DialogContent>
          {image ? (
            <div style={imageDisplay}>
              <img alt="" src={image} width="100%" />
              <div style={buttonsContainer}>
                <Button onClick={back} color="primary" variant="contained">
                  Retake
                </Button>
              </div>
            </div>
          ) : isLoading ? (
            <Skeleton variant="rect" width="100%" height="200px" />
          ) : (
            <Camera image={image} setImage={setImage} setIsLoading={setIsLoading} />
          )}
          <TextField
            margin="dense"
            id="name"
            label="Post"
            type="text"
            fullWidth
            multiline
            inputProps={{ maxLength: 400 }}
            minRows={2}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            disabled={!sendOK}
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
