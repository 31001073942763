import React from 'react'


const GreetingAndGoodbyeStyle = {
	padding: '30px',
}


const Greetings = () => {


	return (
		<div className='Greeting'>
			
			<div style={{
				margin: '160px 0px 40px 0px',
			}}>
				<h1 style={{
					margin: '10px 0px',
				}}>
					Silvester 2022
				</h1>

			</div>
			<p style={{
				backgroundColor: 'rgba(255, 255, 255, 0.5)',
				padding: '16px',
				margin: '30px 0px',
			}}>
				Ein neues Jahr bricht an und das möchte mit dir ausgiebig feiern.
				Kein Schnick Schnack, kein Stress, nur gute Musik, gute Leute und mal wieder eine exclusive Webseite.

				Wir werden einige Biere und Häppchen zur Verfügung stellen, aber generell hoffen wir auf Selbstversorgung. 
				Gegen 8 Uhr starten wir ganz ruhig mit ein paar Fingerfoods und etwa gegen 10 Uhr fangen die ersten DJs an zu spielen.

				Falls du schon registriert bist, kannst du dich <a href='/login/signin'> hier </a> einloggen.<br />
				Aller Anfang ist schwer: sei so lieb und teile mir Bugs mit :)
			</p>
			<p style={{
				padding: '16px',
				margin: '16px 0px',
			}}>
				Paul
			</p>
		</div>
	)
}

export default Greetings