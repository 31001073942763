// credits to https://levelup.gitconnected.com/create-a-signup-page-with-react-and-material-ui-9b203d18cf3f

import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import configData from 'config.json';

const buttonStyle = {
	margin: '0 auto',
}

const ResetPasswordInquiryForm = ({handleError, handleOK, onBackToLogin, style}) => {

    const [email, setEmail] = useState('');
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSuccess = (access_token) => {
		setSuccess(true);
		handleOK(access_token);
		setLoading(false);
	};

	const handleMiss = (error) => {
		handleError(error);
		setLoading(false);
	};

	const handleSubmit = e => {
		e.preventDefault();
		setLoading(true);
        const url = configData.SERVER_URL + '/reset_password_inquiry/' + email;
		fetch(url, {
			method: 'GET',
		})
		.then(async response => response.ok ?  
			handleSuccess((await response.json()).access_token) :
			handleMiss((await response.json()).detail)
		);
	};

	return (
			<div className='ResetPasswordInquiryForm' style={style}>
				{
					success ?
					<div>
						<h1>Success!</h1>
						<p>An email has been sent to {email} with a link to reset your password.</p>
					</div>
					:
					<form onSubmit={handleSubmit}>
						<List>
							<ListItem>
								<TextField
									label="Email"
									variant="outlined"
									required
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
							</ListItem>
							<ListItem>
							<Button disabled={loading} type="submit" 
								variant="contained" color="primary" 
								style={buttonStyle}>
								Reset
							</Button>
							</ListItem>
						</List>
					</form>
				}
				<Button variant="contained" color="secondary" style={buttonStyle} onClick={onBackToLogin}>
					Back to Login
				</Button>
			</div>
	);
};

export default ResetPasswordInquiryForm;