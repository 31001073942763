import React from "react";
import Button from "@mui/material/Button";

const Verified = ({style, onBackToLogin}) => {
    return (
        <div style={style}>
            <h1>Account verified</h1>
            <p  style={{padding: '10px'}} >Thank you for verifying your account. You can now log in.</p>
            <Button variant="contained" onClick={onBackToLogin}>Log in</Button>
        </div>
    );
};

export default Verified;