import * as React from "react";
import { useEffect, useCallback } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
// import Avatar from '@mui/material/Avatar';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ClearIcon from "@mui/icons-material/Clear";
import Skeleton from "@mui/material/Skeleton";

import PostDeleteDialog from "./PostDeleteDialog";

import configData from "config.json";
import * as utils from "utils.js";

const Post = ({ post, onDelete }) => {
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageOK = (payload) => {
    setImage(payload.image);
    setIsLoading(false);
  };

  const loadImage = () => {
    const token = localStorage.getItem("token");
    fetch(configData.SERVER_URL + "/images/" + post.id, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }).then(async (response) =>
      response.ok
        ? handleImageOK(await response.json())
        : utils.handleStandardErrorCode(response)
    );
  };

  const getDate = (created_at) => {
    // remove seconds from ISO string
    var date = new Date(created_at);

    // TODO thats hacky - remove timezone offset
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - timezoneOffset);

    // if date is today return time without seconds
    if (date.toDateString() === new Date().toDateString()) {
      return date.toLocaleTimeString();
    }
    // if date is yesterday return yesterday + time
    if (
      date.toDateString() ===
      new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toDateString()
    ) {
      return "Yesterday " + date.toLocaleTimeString();
    }
    // if date is this year return month + day + time
    if (date.getFullYear() === new Date().getFullYear()) {
      return (
        date.toLocaleString("default", { month: "short", day: "numeric" }) +
        " " +
        date.toLocaleTimeString()
      );
    }
    // if date is not this year return month + day + year + time
    return (
      date.toLocaleString("default", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }) +
      " " +
      date.toLocaleTimeString()
    );
  };

  // interaction observer for image
  const containerRef = React.useRef(null);

  const options = {
    root: null,
    rootMargin: "2000px",
    threshold: 0.1,
  };

  const handleInteractionObserverEntry = useCallback((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        console.log("image is loading");
        loadImage();
        observer.unobserve(entry.target);
      }
    });
  }, []);

  useEffect(() => {
    let observer = null;
    if (post.hasImage) {
      observer = new IntersectionObserver(
        handleInteractionObserverEntry,
        options
      );
      observer.observe(containerRef.current);
    }
    return () => {
      if (observer) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <Card
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        border: "0px solid #e8e8e8",
      }}
      variant="outlined"
      ref={containerRef}
    >
      <CardHeader
        avatar={utils.getAvatar(post.username)}
        action={
          post.deletable && (
            <IconButton
              aria-label="settings"
              onClick={() => {
                handleClickOpen();
              }}
            >
              <ClearIcon />
            </IconButton>
          )
        }
        style={{ padding: '8px' }}
        title={post.username}
        subheader={post.user_title}
      // <div style={{
      //   display: "flex",
      //   flexDirection: "row",
      //   justifyContent: "space-between",
      // }}>
      //   <div>{post.user_title}</div>
      //   <div></div>
      // </div>
      />

      {post.hasImage && isLoading ?
        <Skeleton variant="rectangular" style={{ width: '600px', maxHeight: '600px', minHeight: '300px' }} /> :
        <CardMedia component="img" image={image} />}
      <CardContent style={{padding: '8px'}}>
        <Typography variant='caption'>{getDate(post.created_at)}
        </Typography>
        {post.text && (
          <Typography
            variant="body2"
            sx={{
              // wordBreak: "break-all",
              // whiteSpace: "normal",
            }}
          >
            {post.text}
          </Typography>
        )}
      </CardContent>
      <PostDeleteDialog
        open={open}
        handleClose={handleClose}
        onAccept={onDelete}
      />
    </Card>
  );
};

export default Post;
