import React, { useEffect } from 'react'
import { CardContent, List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';

import configData from 'config.json';
import * as utils from 'utils.js'



const Info = () => {
	const [challenge, setChallenge] = React.useState(null);
	const [isText, setIsText] = React.useState(false);

	const cardSX = {
		'width': '100%',
		'maxWidth': '600px',
		'margin': '0 auto',
		'border': "0px solid #e8e8e8",
	}

	const handleChallengeOK = (response) => {
		setChallenge(response.challenge);
		setIsText(response.isText);
	}
	
	const getChallenge = () => {
		const token = localStorage.getItem('token');
		fetch(configData.SERVER_URL + '/challenge', {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + token
			}
		})
		.then(async response => response.ok ?
			handleChallengeOK(await response.json()) :
			utils.handleStandardErrorCode(response)
		);
	}


	useEffect(() => {
		getChallenge();
	}, []);

	return (
		<div>
			<List disablePadding>
				{challenge &&
				<ListItem disableGutters key="challenge">
					<Card sx={{
						...cardSX,
						'background': 'linear-gradient(160deg, rgb(254 212 225) 30%, rgb(253 254 190) 100%)'
					}} >
						<CardContent>
							<Typography variant="h5" component="div" gutterBottom>
								Challenge
							</Typography>

							<Typography variant="subtitle2" component="div" gutterBottom>
								{challenge}
							</Typography>
							<Typography variant="body2" component="div" gutterBottom>

								<List >
									<ListItem key="explain"><Typography>~ Teil' deine Challenge nicht mit Anderen, bevor sie nicht gelöst wurde</Typography></ListItem>
									{
										isText ?
											<ListItem key="todoText"><Typography>~ Post' den Beweis über die vollendete Challenge als Text im Guestbook</Typography></ListItem>
										:
											<ListItem key="todoImage"><Typography>~ Post' den Beweis über die vollendete Challenge als Bild/Gif im Guestbook</Typography></ListItem>
									}
									<ListItem key="surprise"><Typography>~ Frag' Paul Henri nach einer Belohnung</Typography></ListItem>
								</List>
							</Typography>

						</CardContent>
					</Card>
				</ListItem>
				}
				<ListItem disableGutters key="timetable">
					<Card sx={{
						...cardSX,
						'background': 'linear-gradient(215deg, rgb(253 254 190) 30%, rgb(175 232 254) 100%)'
					}} >
						<CardContent>
							<Typography variant="h5" component="div" gutterBottom>
								Timetable
							</Typography>
							<List >
								<ListItem key="dj1"><Typography>22:00 ??? @Foyer</Typography></ListItem>
								<ListItem key="dj2"><Typography>00:15 <a href="https://soundcloud.com/comtee">Comté</a> @Foyer</Typography></ListItem>
								<ListItem key="dj3"><Typography>02:00 <a href="https://soundcloud.com/paul_henri">Paul Henri</a> @Keller</Typography></ListItem>
								<ListItem key="dj4"><Typography>04:00 <a href="https://soundcloud.com/minimalboese">pheni>>boi</a> @Keller</Typography></ListItem>
							</List>
						</CardContent>
					</Card>
				</ListItem>
				<ListItem disableGutters key="maininfo">
					<Card sx={{
						...cardSX,
						'background': 'linear-gradient(135deg, rgb(175 232 254) 30%, rgb(254 212 225) 100%)'
					}}
					>
						<CardContent>
							<Typography variant="h5" component="div" gutterBottom>
								Info
							</Typography>
							<List >
								<ListItem key="wann"><Typography>Wann? 31ter Dez, 20:00 Uhr</Typography></ListItem>
								<ListItem key="wo"><Typography>Wo? <a href="https://goo.gl/maps/8KBfQqRNb71GueMc9">Rüppurr, Lebrechtstr. 12</a></Typography></ListItem>
							</List>
							<Typography component="div" gutterBottom>
								Ich würde mich sehr freuen, wenn du dir etwas Extravagantes anziehst.
							</Typography>
						</CardContent>
					</Card>
				</ListItem>
			</List>
		</div>
	)
}

export default Info