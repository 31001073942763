import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { List, ListItem } from "@mui/material";
import { useLocation } from "react-router-dom";


import configData from "config.json";
import { checkPassword } from "utils";


const buttonStyle = {
	margin: '0 auto',
}

const ResetPasswordForm = ({handleError, onBackToLogin, style}) => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

	const location = useLocation();

    
	const handleSuccess = () => {
		setSuccess(true);
		setLoading(false);
	};

	const handleMiss = (error) => {
		handleError(error);
		setLoading(false);
	};

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (checkPassword(password, confirmPassword) == false) {
            handleError("Passwords do not match");
        }
        // get token from url pathname
        const token = location.pathname.split("/")[3];
        const url = configData.SERVER_URL + "/reset_password";
        const data = {
            token: token,
            password: password,
        };
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then (async response =>  
                response.ok ? 
                    handleSuccess() :
                    handleMiss((await response.json()).detail));
    }

    return (
        <div>
        <div className="ResetPasswordForm" style={style}>
            {
            success ?
                <div>
                    <h1>Success!</h1>
                    <p>Your password has been reset. You can now log in with your new password.</p>
                    <Button
                        variant="contained"
                        color="secondary"
                        style={buttonStyle}
                        onClick={onBackToLogin} >
                        Log In
                    </Button>
                </div>
            :
                <form onSubmit={handleSubmit}>
                    <List>
                        <ListItem>
                            <TextField
                                label="New Password"
                                variant="outlined"
                                type="password"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)} />
                        </ListItem>
                        <ListItem>
                            <TextField
                                label="Confirm Password"
                                variant="outlined"
                                type="password"
                                required
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)} />
                        </ListItem>
                        <ListItem>
                            <Button
                                disabled={loading}
                                style={buttonStyle}
                                variant="contained"
                                type="submit" >
                                Reset Password
                            </Button>
                        </ListItem>
                    </List>
                </form>
            }
        </div>
    </div>
    );
};

export default ResetPasswordForm;
