// credits to https://levelup.gitconnected.com/create-a-signup-page-with-react-and-material-ui-9b203d18cf3f

import React, { useEffect, useState } from 'react';
import {Outlet, useNavigate, useLocation, Route, Routes} from 'react-router-dom';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Flamingo from 'components/other/flamingo/Flamingo';
import LoginForm from './LoginForm';
import ResetPasswordForm from 'components/login/ResetPasswordForm';
import ResetPasswordInquiryForm from './ResetPasswordInquiryForm';
import Verified from './Verified';


const loginStyle = {
	// 'height': '100%',
	'position': 'absolute',
	'width': '100%',
	'top': '50%',
	'display': 'flex',
	'flexDirection': 'column',
	'justifyContent': 'center',
	'alignItems': 'center',
}

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const Login = ({ }) => {

	const [error, setError] = useState('');
	const [openError, setOpenError] = React.useState(false);

	const navigate = useNavigate();
	const location = useLocation();


	const handleOK = (token, userName) => {
		localStorage.setItem('token', token);
		localStorage.setItem('username', userName);
		navigate('/info');
	}

	const handleError = (error) => {
	  setError(error);
	  setOpenError(true);
	};
  
	const handleCloseError = (event, reason) => {
	  if (reason === "clickaway") {
		return;
	  }
	  setOpenError(false);
	};


	useEffect(() => {
		if (location.pathname === '/login/' || location.pathname === '/login') {
			navigate('/login/signin');
		}
	}, [location]);

	

	return (
		<>
		<Routes>		
			<Route path="/signin" element={
				<LoginForm handleError={handleError} handleOK={handleOK} 
					onForgotPassword={() => navigate('forgot_password')} 
					style={loginStyle}/> } />
			<Route path="/forgot_password" element={
				<ResetPasswordInquiryForm handleError={handleError} 
					handleOK={ () => {} } 
					style={loginStyle}
					onBackToLogin={() => navigate('signin')}
					/> } />
			<Route path="/reset_password/*" element={
				<ResetPasswordForm handleError={handleError}
					handleOK={ () => {} }
					onBackToLogin={() => navigate('signin')}
					style={loginStyle}
					/>
			} />
			<Route path="/verified" element={
				<Verified 
					onBackToLogin={() => navigate('signin')}
					style={loginStyle}
					/>
			} />
		</Routes>
		
		<Snackbar
			open={openError}
			autoHideDuration={6000}
			onClose={handleCloseError} >
			<Alert
				onClose={handleCloseError}
				severity="error"
				sx={{ width: "100%" }} >
				{error}
			</Alert>
		</Snackbar>
		<Flamingo />
	</>
	);
};

export default Login;