// credits to https://levelup.gitconnected.com/create-a-signup-page-with-react-and-material-ui-9b203d18cf3f

import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Checkbox, List, ListItem, ListItemText } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useLocation } from "react-router-dom"

import configData from "config.json";

import { checkEmail, checkPassword, checkUsername, checkFullName } from "utils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const formStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
};

const listStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
};

const listItemStyle = {
	margin: "0 auto",
	width: "100%",
	maxWidth: "300px",
};
const buttonStyle = {
  margin: "0 auto",
};

const Registration = () => {
  
	const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [notifications, setNotifications] = useState(false);
  const [dj, setDj] = useState(false);

  const [error, setError] = useState("");
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [registered, setRegistered] = React.useState(false);
  const [registration_token, setRegistrationToken] = React.useState("");

  const resetState = () => {
    setFullName("");
    setUserName("");
    setEmail("");
    setPassword("");
    setNotifications(false);
    setDj(false);
  };

  const handleSuccess = () => {
    setOpenSuccess(true);
    setRegistered(true);
    resetState();
    setLoading(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleError = (error) => {
    setError(error);
    setOpenError(true);
    setLoading(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const checkForm = () => {
    if (!checkFullName(fullName, handleError)) {
      return false;
    }
    console.log('name is good');
    if (!checkUsername(userName, handleError)) {
      return false;
    }
    console.log('username is good');
    if (!checkEmail(email, handleError)) {
      return false;
    }
    console.log('email is good');
    if (!checkPassword(password, confirmPassword, handleError)) {
      return false;
    }
    console.log('password is good');
    return true;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (!checkForm()) {
      console.log("Form is not valid");
      return;
    }

    // check if registration token is valid
    if (registration_token === "" || registration_token === undefined) {
      handleError("You do not have a valid registration link. Contact Paul.");
      return;
    }

    fetch(configData.SERVER_URL + "/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        full_name: fullName,
        username: userName,
        email: email,
        password: password,
        notifications: notifications,
        dj: dj,
        registration_token: registration_token,
      }),
    }).then(async (response) =>
      response.ok
        ? handleSuccess()
        : handleError((await response.json()).detail)
    );
  };

  useEffect(() => {
    // get token from url pathname
    const registration_token = location.pathname.split("/")[2];
    setRegistrationToken(registration_token);
  }, []);
    
  

  return (
    <div id="registration" style={formStyle}>
      {registered ? null : 
        registration_token ?
        <form onSubmit={handleSubmit}>
          <List style={listStyle}>
            <ListItem>
              <TextField
                label="Full name"
                variant="outlined"
                required
                value={fullName}
                style={listItemStyle}
                onChange={(e) => setFullName(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Username"
                variant="outlined"
                required
                value={userName}
                style={listItemStyle}
                onChange={(e) => setUserName(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                required
                value={email}
                style={listItemStyle}
                onChange={(e) => setEmail(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Password"
                variant="outlined"
                type="password"
                required
                value={password}
                style={listItemStyle}
                onChange={(e) => setPassword(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <TextField
                label="Password Confirmation"
                variant="outlined"
                type="password"
                required
                value={confirmPassword}
                style={listItemStyle}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </ListItem>
            <ListItem>
              <FormControlLabel
                style={listItemStyle}
                control={
                  <Checkbox
                    checked={notifications}
                    onChange={(e) => setNotifications(!notifications)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Ja, Ich möchte über weitere Veranstaltungen benachrichtigt werden"
              />
            </ListItem>
            <ListItem>
              <FormControlLabel
                style={listItemStyle}
                control={
                  <Checkbox
                    checked={dj}
                    onChange={(e) => setDj(!dj)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Ja, ich würde gerne auflegen."
              />
            </ListItem>
            <ListItem>
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                color="primary"
                style={buttonStyle}
              >
                Sign Up
              </Button>
            </ListItem>
          </List>
        </form>
      :
      null    
    }

      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Thanks for signing up. An email has been sent to your email address. Please check your Spam folder, too.
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Registration;
