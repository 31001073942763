import React from 'react'

import Particles from "react-tsparticles";
import './ParticleSystem.css';

const ParticleSystem = ({opacity}) => {
    
	const particlesInit = (main) => {
		// console.log(main);
	  };
	
	const particlesLoaded = (container) => {
	// console.log(container);
	};
	const particleOptions = {
		background: {
			color: {
			value: "#fff",
			},
		},
		fpsLimit: 1.03,
		interactivity: {
			events: {
				onClick: {
					enable: false,
					mode: "push",
				},
				onHover: {
					enable: false,
					mode: "repulse",
				},
				resize: true,
			},
			modes: {
				bubble: {
					distance: 400,
					duration: 2,
					opacity: 0.8,
					size: 40,
				},
				push: {
					quantity: 4,
				},
				repulse: {
					distance: 200,
					duration: 0.4,
				},
			},
		},
		particles: {
			color: {
				value: "#000",
			},
			links: {
				color: "#000",
				distance: 150,
				enable: true,
				opacity: 0.2,
				width: 1,
				triangles: {
					enable: true,
					color: 'random',
					opacity: 0.2,
				},

			},
			collisions: {
				enable: false,
			},
			move: {
				direction: "none",
				enable: false,
				outMode: "bounce",
				random: true,
				speed: 0.3,
				straight: false,
			},
			number: {
				density: {
					enable: true,
					area: 300,
				},
				value: 30,
			},
			opacity: {
				value: 0,
			},
			shape: {
				type: "circle",
			},
			size: {
				random: true,
				value: 5,
			},
		},
		detectRetina: true,
	}

  return (
    <div style={{
		position: 'absolute',
		zIndex: -1,
	}}>
			<Particles
				className="particles"
				canvasClassName="particlesCanvas"
				id="tsparticles"
				init={particlesInit}
				loaded={particlesLoaded}
				options={particleOptions}
				height="10%"
				width="10%"
			/>
    </div>
  )
}

export default ParticleSystem