import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import React from 'react';
import Posts from './components/posts/Posts';
import Info from 'components/info/Info';
import Welcome from './components/welcome/Welcome';
import Login from './components/login/Login';
import Home from './components/main/Home';

import {createTheme, ThemeProvider} from '@mui/material/';


let theme = createTheme({
	palette: {
	  primary: {
		  main: 'rgb(254 143 178)',
	  },
	  secondary: {
		  main: '#C6E5D9',
	  },
	},
	typography: {
	  fontFamily: '"Montserrat", "Roboto", "-apple-system", sans-serif',
	},
});


function App() {

	const [posts, setPosts] = React.useState([]);
	const [isInitialized, setIsInitialized] = React.useState(false);

	return (
			<div className="App">
				<ThemeProvider theme={theme}>
					<BrowserRouter >
							<Routes>
								<Route path="invite/*" element={<Welcome />} />
								<Route path="/" element={<Home />}>
									<Route path='posts' element={<Posts 
										posts={posts} setPosts={setPosts}
										isInitialized={isInitialized} setIsInitialized={setIsInitialized}/>}/>
									<Route path='info' element={<Info />}/>
								</Route>
								<Route path='login/*' element={<Login />}/>
							</Routes>
					</BrowserRouter>
				</ThemeProvider>
			</div>
	);
}

export default App;
