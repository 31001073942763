import React, { useEffect } from 'react'
import BotNav from 'components/main/BotNav';
import Info from 'components/info/Info';
import Posts from 'components/posts/Posts';
import { Route, Routes, Outlet, Navigate, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom"


const Home = () => {

	const nav = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (localStorage.getItem("token")) {
			if (location.pathname === "/") {
				nav('/info');
			}
		}
		else {
			nav('/login');
		}
	}, []);

	return (
		<div>
			<BotNav></BotNav>
				<div style={{marginTop: '60px'}}>
					<Outlet></Outlet>
				</div>
		</div>
	)
}

export default Home