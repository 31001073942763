import * as React from 'react';
import { useNavigate } from 'react-router';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Paper, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import {useLocation} from 'react-router-dom';
import {useEffect} from 'react';

import * as utils from 'utils.js';

const boxStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px',
    margin: '0 auto',
    maxWidth: '600px',
};

const paperStyle = {
    position: 'fixed',
    top: '0px',
    width: '100%',
    zIndex: 4,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0)',
    borderRadius: '0px',
};

export default function SimpleBottomNavigation() {
    const [value, setValue] = React.useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    
    const logout = () => {
        handleCloseUserMenu();
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        navigate('/login');
    };

    useEffect(() => {
        let loc = location.pathname.split('/')[1]
        if (loc === 'info') {
            setValue(0);
        } else if (loc === 'posts') {
            setValue(1);
        }
    }, [])
    

    return (
        <div >
            <Paper style={paperStyle}>
                <Box style={boxStyle}>
                    <BottomNavigation
                        value={value}>
                        <BottomNavigationAction label="Info" showLabel onClick={() => {
                            navigate('/info');
                            setValue(0);
                        }} />
                        <BottomNavigationAction label="Guestbook" showLabel onClick={() => {
                            navigate('/posts');
                            setValue(1);
                        }} />
                    </BottomNavigation>
                    <IconButton onClick={handleOpenUserMenu}>
                        {localStorage.getItem('username') && utils.getAvatar(localStorage.getItem('username'))}
                    </IconButton>
                    
                    <Menu
                            // sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key='logout' onClick={logout}> Logout </MenuItem>
                    </Menu>
                </Box>
            </Paper>
        </div>
    );
}