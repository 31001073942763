import React from 'react'
import imageCompression from 'browser-image-compression';
import { Input, IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Camera = ({ setImage, setIsLoading }) => {

	const [error, setError] = React.useState("");
	const [openError, setOpenError] = React.useState(false);

	const handleError = (error) => {
		setError(error);
		setOpenError(true);
	};

	const handleCloseError = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenError(false);
	};


	// credits to https://www.npmjs.com/package/browser-image-compression
	async function compress(imageFile) {

		if (!imageFile.type.includes("image")) {
			handleError("Please select an png, jpeg or gif file.");
			return;
		}

		const fileSize = imageFile.size / 1024 / 1024
		console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
		console.log(`originalFile size ${fileSize} MB`);
		setIsLoading(true);

		const options = {
			maxSizeMB: 0.2,
			useWebWorker: true,
			maxWidthOrHeight: 1920,
		}

		var compressedFile = imageFile;
		if (imageFile.type === 'image/gif') {
			if (fileSize > 3.0) {
				setIsLoading(false);
				handleError('Please select a gif that is smaller than 3MB.');
				return;
			}
		}
		else if (fileSize > options.maxSizeMB) {
			try {
				compressedFile = await imageCompression(imageFile, options);
				console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
				console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
			}
			catch (error) {
				handleError('Argh! Something went wrong.');
				console.log(error);
				setIsLoading(false);
				return;
			}
		}
		try {
			const compressedDataURL = await imageCompression.getDataUrlFromFile(compressedFile);
			setImage(compressedDataURL);
		} catch (error) {
			console.log(error);
			handleError('Argh! Something went wrong.');
			setIsLoading(false);
			return;
		}
		setIsLoading(false)
	}


	return (
		<div>
			<label htmlFor="contained-button-file">
				<input accept="image/png, image/gif, image/jpeg" id="contained-button-file" type="file" style={{ display: 'none' }}
					onChange={e => {
						const file = e.target.files[0];
						compress(file);
					}} />
				<IconButton color="primary" aria-label="upload picture" component="span">
					<PhotoCamera />
				</IconButton>
			</label>

			<Snackbar
				open={openError}
				autoHideDuration={6000}
				onClose={handleCloseError}
			>
				<Alert
					onClose={handleCloseError}
					severity="error"
					sx={{ width: "100%" }}
				>
					{error}
				</Alert>
			</Snackbar>
		</div>
	)
}

export default Camera