import { Button, List, ListItem } from '@mui/material';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect } from 'react'

import Post from 'components/posts/post/Post'
import PostEditor from 'components/posts/postEditor/PostEditor'

import configData from 'config.json';
import * as utils from 'utils.js'


const Posts = ({posts, setPosts, isInitialized, setIsInitialized}) => {

	const [open, setOpen] = React.useState(false);
	const [noMorePosts, setNoMorePosts] = React.useState(false);


	const removeDuplicates = (response, posts) => {
		return response.filter((r_post) => !posts.some( (p) => p.id === r_post.id ));
	}

	const handleDeleteOK = (id) => {
		setPosts(posts.filter(post => post.id !== id));
	}

	const handleNewItemsOK = (response) => {
		response = removeDuplicates(response, posts);
		setPosts([...response, ...posts]);
	}

	const handleOldItemsOK = (response) => {
		const maxLoadedPosts = 100;
		if (response.length === 0 || response.length < maxLoadedPosts) {
			setNoMorePosts(true);
		}
		response = removeDuplicates(response, posts);
		setPosts([...posts, ...response]);
	}

	const initPosts = () => {
		getPosts(null, handleNewItemsOK);
		setIsInitialized(true);
	}

	const refresh = () => {
		console.log("refresh");
		const newestPost = posts[0];
		if (newestPost) {
			getPosts({'date': newestPost.created_at, 'older_than': false}, handleNewItemsOK);
		}
		else {
			initPosts();
		}
	}

	const loadmore = () => {
		const oldestpost = posts[posts.length - 1];
		if (oldestpost) {
			getPosts({'date': oldestpost.created_at, 'older_than': true}, handleOldItemsOK);
		}
	}

	const getPosts = (options, handle) => {
		const token = localStorage.getItem('token');
		const url = new URL(configData.SERVER_URL + '/posts');
		if (options) {
			url.searchParams.append('date', options.date);
			url.searchParams.append('older_than', options.older_than);
		}
		fetch(url, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + token
			}
		})
		.then(async response => response.ok ?
			handle( (await response.json()) ) :
			utils.handleStandardErrorCode(response)
		);
	}

	const deleteItem = (id) => {
		// make a request to the server to delete the item
		const token = localStorage.getItem('token');
		fetch(configData.SERVER_URL + '/posts/' + id, {
			method: 'DELETE',
			headers: {
				'Authorization': 'Bearer ' + token
			}
		})
		.then(async response => response.ok ?
			handleDeleteOK( id ) :
			utils.handleStandardErrorCode(response)
		);
	}

	useEffect(() => {
		if (!isInitialized) {
			initPosts();
		}
		const timer = setInterval(() => {
			if (isInitialized) {
				refresh();
			}
		}, configData.REFRESH_INTERVAL);

		return () => clearTimeout(timer);
	}, [posts]);


  return (
	<div>
		<List disablePadding>
			{
				posts.map( post => (
					<ListItem key={post.id} disableGutters>
						<Post post={post} onDelete={() => {
							deleteItem(post.id)
						}}/>
					</ListItem>
				))
			}
		</List>
		{ !noMorePosts &&
			<Button 
				style={{margin: '0 auto', position: 'relative', display: 'block'}} 
				onClick={() => {
					loadmore();
				}}>
				Load more
			</Button>
		}

		<Fab 
				style={{
					position: 'fixed',
					bottom: '8px',
					right: '8px',
					borderRadius: '50%',}} 
			aria-label='Add' color='primary' onClick={()=> {setOpen(true)}}>
			<AddIcon />
		</Fab>

		<PostEditor open={open} setOpen={setOpen} onAccept={refresh}/>
	</div>
  )
}

export default Posts