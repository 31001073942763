
// response status codes
// 200: OK
// 201: Created
// 202: Accepted
// 204: No Content
// 400: Bad Request
// 401: Unauthorized
// 403: Forbidden
// 404: Not Found
// 500: Internal Server Error
// 502: Bad Gateway
// 503: Service Unavailable
// 504: Gateway Timeout


import Avatar from "boring-avatars";

const httpStatusCodeRoute = new Map();
httpStatusCodeRoute.set(401, '/login');
httpStatusCodeRoute.set(403, '/login');
// httpStatusCodeRoute.set(404, '/404');
// httpStatusCodeRoute.set(500, '/500');

const handleStandardErrorCode = (error) => {
   window.location.href =  httpStatusCodeRoute.get(error.status)
}


const checkPassword = (password, confirmPassword, handleError) => {
    if (password !== confirmPassword) {
      handleError("Passwords do not match");
      return false;
    }
    if (password.length < 4) {
      handleError("Password must be at least 4 characters");
      return false;
    }
    // check if password only contains numbers, letters, special characters and no spaces
    if (!password.match(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};:\|,.<>\/?]*$/)) {
      handleError("Password must only contain letters, numbers, and special characters like !@#$%^&*()_+-=[]{};:\|,.<>/?");
      return false;
    }
    return true;
  };


  const checkEmail = (email, handleError) => {
    // check if email has an text @ text . text
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      handleError("Email must be a valid email address");
      return false;
    }
    return true;
  };


  const checkUsername = (userName, handleError) => {
    if (userName.length < 3) {
      handleError("Username must be at least 3 characters");
      return false;
    }
    // check if username only contains letters, numbers any of the following: _ . > < ~ and no spaces
    if (!userName.match(/^[a-zA-Z0-9_\.\>\<\~]+$/)) {
      handleError("Username can only contain letters, numbers,  _ . > < ~ and no spaces");
      return false;
    }
    return true;
  };


  const checkFullName = (fullName, handleError) => {
    if (fullName.length < 3) {
      handleError("Full name must be at least 3 characters");
      return false;
    }
    if (!fullName.match(/^[a-zA-Z ]+$/)) {
      handleError("Full name can only contain letters and spaces");
      return false;
    }
    if (fullName.match(/\s\s/)) {
      handleError("Full name must be at least two words");
      return false;
    }
    return true;
  };


function getAvatar(name) {
	return <Avatar
		size={40}
		name={name}
		variant="bauhaus"
		colors={
			// ["#EFEECC", "#FE8B05", "#FE0557", "#400403", "#0AABBA"]
			// ["#A7C5BD", "#E5DDCB", "#EB7B59", "#CF4647", "#524656"]
			// ["#805841", "#DCF7F3", "#FFFCDD", "#FFD8D8", "#F5A2A2"] // Vuitton's Breakfast
			// ["#A8E6CE", "#DCEDC2", "#FFD3B5", "#FFAAA6", "#FF8C94"] // 400 unique artists ?
			// ["#E94E77", "#D68189", "#C6A49A", "#C6E5D9", "#F4EAD5"] // Lovers in Japan
			// ["#b7ded2", "#f6a6b2", "#ffd3b6", "#ffaaa5", "#ff8b94"] // carnival vintage
			// ['#C7FCD7']
			// ['rgb(253 254 190)', 'rgb(254 206 232)', 'rgb(191 186 255)', 'rgb(175 232 254)', 'rgb(203 255 229)']
			['rgb(253 254 190)', 'rgb(254 212 225)', 'rgb(191 186 255)', 'rgb(175 232 254)', 'rgb(203 255 229)']
		}
	/>
}
export {
	   handleStandardErrorCode,
	   checkPassword,
	   checkEmail,
	   checkUsername,
	   checkFullName,
	//    stringToColor,
	//    stringAvatar,
	   getAvatar,
};