// credits to https://levelup.gitconnected.com/create-a-signup-page-with-react-and-material-ui-9b203d18cf3f

import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import configData from 'config.json';

const buttonStyle = {
	margin: '0 auto',
}

const LoginForm = ({handleError, handleOK, onForgotPassword, style}) => {

	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');


	const handleSuccess = (response) => {
		handleOK(response, userName);
		setPassword('');
	};

	const handleSubmit = e => {
		e.preventDefault();
		let formData = new FormData();
		formData.append('username', userName);
		formData.append('password', password);
		fetch(configData.SERVER_URL + '/token', {
			method: 'POST',
			body: formData,
		})
		.then(async response => response.ok ?  
			handleSuccess((await response.json()).access_token) :
			handleError((await response.json()).detail)
		);
	};

	return (
			<div className='LoginForm' style={style}>
				<form onSubmit={handleSubmit}>
					<List>
						<ListItem>
							<TextField
								label="Username"
								variant="outlined"
								required
								value={userName}
								onChange={e => setUserName(e.target.value)}
							/>
						</ListItem>
							<ListItem>
							<TextField
								label="Password"
								variant="outlined"
								type="password"
								required
								value={password}
								onChange={e => setPassword(e.target.value)}
							/>
							</ListItem>
							<ListItem>
							<Button type="submit" variant="contained" color="primary"
								style={buttonStyle}>
								Sign in
							</Button>
						</ListItem>
					</List>
				</form>
				<Button type="button" variant="contained" color="secondary" 
					style={buttonStyle} onClick={onForgotPassword}>
					Forgot Password ?</Button>
			</div>
	);
};

export default LoginForm;