import React from 'react'
import { useEffect } from 'react'

import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import './Flamingo.css';


const Flamingo = () => {

	useEffect(() => {

			let camera, scene, renderer;
			const mixers = [];

			const clock = new THREE.Clock();

			init();
			animate();

			function init() {

				const container = document.getElementById( 'container' );

				camera = new THREE.PerspectiveCamera( 30, window.innerWidth / window.innerHeight, 1, 5000 );
				camera.position.set( 0, 0, 250 );

				scene = new THREE.Scene();
				
				scene.background = new THREE.Color().setHSL( 0.6, 0, 1 );
				// scene.background = new THREE.Color().setHSL( 0.2, 1, 0 );
				scene.fog = new THREE.Fog( scene.background, 1, 10000 );

				// LIGHTS

				const hemiLight = new THREE.HemisphereLight( 0xffffff, 0xffffff, 0.6 );
				hemiLight.color.setHSL( 0.6, 1, 0.6 );
				hemiLight.groundColor.setHSL( 0.095, 1, 0.75 );
				hemiLight.position.set( 0, 50, 0 );
				scene.add( hemiLight );


				const dirLight = new THREE.DirectionalLight( 0xffffff, 1 );
				dirLight.color.setHSL( 0.1, 1, 0.95 );
				dirLight.position.set( - 1, 1.75, 1 );
				dirLight.position.multiplyScalar( 30 );
			
				scene.add( dirLight );

				dirLight.castShadow = true;

				dirLight.shadow.mapSize.width = 2048;
				dirLight.shadow.mapSize.height = 2048;

				const d = 50;

				dirLight.shadow.camera.left = - d;
				dirLight.shadow.camera.right = d;
				dirLight.shadow.camera.top = d;
				dirLight.shadow.camera.bottom = - d;

				dirLight.shadow.camera.far = 3500;
				dirLight.shadow.bias = - 0.0001;

				// const hemiLightHelper = new THREE.HemisphereLightHelper( hemiLight, 10 );
				// scene.add( hemiLightHelper );
				// const dirLightHelper = new THREE.DirectionalLightHelper( dirLight, 10 );
				// scene.add( dirLightHelper );

				// SKYDOME

				const vertexShader = `
					varying vec3 vWorldPosition;
					void main() {
						vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
						vWorldPosition = worldPosition.xyz;
						gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
					}`;
				const fragmentShader = `
					uniform vec3 topColor;
					uniform vec3 bottomColor;
					uniform float offset;
					uniform float exponent;
					varying vec3 vWorldPosition;
					void main() {
						float h = normalize( vWorldPosition + offset ).y;
						gl_FragColor = vec4( mix( bottomColor, topColor, max( pow( max( h , 0.0), exponent ), 0.0 ) ), 1.0 );
					}`;
				
				// const sunset = 0xFAD6A5;
				// const bluesky = 0x1177ff;
				// const sunset_bottom = 0xFAD6A5;
				// const bluesky_bottom = 0xfffff;

				const sunset = {
					'topColor': { value: new THREE.Color( 0xFAD6A5 ) },
					'bottomColor': { value: new THREE.Color( 0xFAD6A5 ) },
					'offset': { value: 0 },
					'exponent': { value: 0.6 }
				}

				const bluesky = {
					'topColor': { value: new THREE.Color( 0x1177ff ) },
					'bottomColor': { value: new THREE.Color( 0xffffff ) },
					'offset': { value: 0 },
					'exponent': { value: 0.6 }
				};

				const night = {
					'topColor': { value: new THREE.Color( 0x000000 ) },
					'bottomColor': { value: new THREE.Color( 0x000000 ) },
					'offset': { value: 0 },
					'exponent': { value: 0.6 }
				};

				const uniforms = bluesky;

				// uniforms[ 'topColor' ].value.copy( hemiLight.color );

				scene.fog.color.copy( uniforms[ 'bottomColor' ].value );
				

				const skyGeo = new THREE.SphereGeometry( 4000, 32, 15 );
				const skyMat = new THREE.ShaderMaterial( {
					uniforms: uniforms,
					vertexShader: vertexShader,
					fragmentShader: fragmentShader,
					side: THREE.BackSide
				} );

				const sky = new THREE.Mesh( skyGeo, skyMat );
				scene.add( sky );

				// MODEL

				const loader = new GLTFLoader();

				loader.load( '/models/Flamingo.glb', function ( gltf ) {

					const mesh = gltf.scene.children[ 0 ];

					const s = 0.3;
					mesh.scale.set( s, s, s );
					mesh.position.y = 27;
					mesh.rotation.y = - 1;

					mesh.castShadow = true;
					mesh.receiveShadow = true;
					mesh.name = 'Flamingo';

					scene.add( mesh );

					const mixer = new THREE.AnimationMixer( mesh );
					mixer.clipAction( gltf.animations[ 0 ] ).setDuration( 1 ).play();
					mixers.push( mixer );

				} );

				// RENDERER

				renderer = new THREE.WebGLRenderer( { antialias: true } );
				renderer.setPixelRatio( window.devicePixelRatio );
				renderer.setSize( window.innerWidth, window.innerHeight );
				container.appendChild( renderer.domElement );
				renderer.outputEncoding = THREE.sRGBEncoding;
				renderer.shadowMap.enabled = true;

				window.addEventListener( 'resize', onWindowResize );

			}

			function onWindowResize() {
				camera.aspect = window.innerWidth / window.innerHeight;
				camera.updateProjectionMatrix();
				renderer.setSize( window.innerWidth, window.innerHeight );
			}

			function animate() {
				requestAnimationFrame( animate );
				render();
			}

			function render() {
				const delta = clock.getDelta();
				for ( let i = 0; i < mixers.length; i ++ ) {
					mixers[ i ].update( delta );
				}
				renderer.render( scene, camera );
			}

	}, []);

	return (
		<div id="container" style={{width: '100%'}}>
		</div>
	)
}

export default Flamingo