import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function PostDeleteDialog({open, handleClose, onAccept}) {

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose} >
        <DialogTitle>{"Do you really want to delete your post?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
			  This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={() => {handleClose(); onAccept()}}>Agree</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}